import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Button from 'react-bootstrap/Button'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

class NLLSubmit extends React.Component {
  render() {
    return (
      <Container className='Portal-FormContainerInput' key={this.props.name}>
        <Row className='Portal-FormRowSubmit'>
          <Button
            variant="primary"
            disabled={this.props.isDisabled}
            type="submit"
            key={this.props.name}
            onClick={this.props.handleClick}
            className={this.props.className}
          >
            {this.props.isDisabled ? this.props.missingText : this.props.submitText }
          </Button>
        </Row>
      </Container>
    )
  }
}

NLLSubmit.propTypes = {
  submitText: PropTypes.string,
  missingText: PropTypes.string,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  className: PropTypes.string,
}

export default NLLSubmit
