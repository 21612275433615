import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import OldBanner from './img/version22.png'
import NewBanner from './img/version24.png'
import portalConfig from './portalConfig'

import { withTranslation } from 'react-i18next';

class Banner extends React.Component {
    render() {
        return (
            <div className="App-Banner">
                <div className="choose-product-header">
                    <p className="step-1">{this.props.t("banner.chooseVersion")}</p>                   
                    <p className="step-2">{this.props.t("banner.selectNortonProduct")}</p>
                    <p className="step-3">{this.props.t("banner.aboutProduct")}</p>
                </div>
                <div className="version-banner-section">
                    <div className="version-banner">
                        <div className="banner-1-section">
                            <div className="banner-1">
                                <div className="products-list">
                                    <p>{this.props.t("banner.product-1_3")} </p>
                                    <p>{this.props.t("banner.product-1_1")} </p>
                                    <p>{this.props.t("banner.product-1_2")} </p>                                   
                                </div>
                                <div className="submission-link">
                                    <a href={portalConfig["old_version_url"]} target="_blank" rel="noopener noreferrer">{this.props.t("banner.fileSubmission")}</a>
                                    <a href={portalConfig["old_url_dispute_link"]} target="_blank" rel="noopener noreferrer">{this.props.t("banner.urlSubmission")}</a>
                                </div>
                            </div>
                            <div className="banner-image">
                                <p className="image-header">{this.props.t("banner.version22_img_header")} </p>
                                <img src={OldBanner} className="old-banner" alt="version 22.x banner" />
                            </div>
                            
                        </div>
                        <div className="banner-2-section">
                            <div className="banner-2">
                                <div className="products-list">
                                    <p>{this.props.t("banner.product-2_2")}</p>
                                    <p>{this.props.t("banner.product-2_1")}</p>                                    
                                </div>
                                <div className="submission-link">
                                    <a href={portalConfig["new_version_url"]} target="_blank" rel="noopener noreferrer">{this.props.t("banner.urlAndFileSubmission")}</a>
                                </div>
                            </div>
                            <div className="banner-image">
                                <p className="image-header">{this.props.t("banner.version24_img_header")} </p>
                                <img src={NewBanner} className="new-banner" alt="version 24.x banner" />
                            </div>
                            
                        </div>
                    </div>
                </div>  
            </div>
        ) 
    }
}

export default withTranslation()(Banner)