import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import { withTranslation } from 'react-i18next';

import NLLURLInput from './NLLURLInput'
import NLLURLSubmission from './NLLURLSubmission'

class NLLURL extends React.Component {
  constructor(props) {
    super(props);
    this.setURL = this.setURL.bind(this)
    this.unsetURL = this.unsetURL.bind(this)
    let test = false
    //test = this.test()
    this.state = {
      url: ( test ? test['url'] : "" ),
      allClassifications: ( test ? test['all_classifications'] : [] ),
      currentClassifications: ( test ? test['current_classifications'] : [] ),
      isLocked: ( test ? test['is_locked'] : null ),
      submissionID: false,
      messageToUser: false,
      error: {
      }
    }

  }

  test() {
    let current_classifications = [
      {
        id: 1,
        name: "test classification 1",
        description: ""
      },
      {
        id: 3,
        name: "test classification 3",
        description: ""
      }
    ]
    let all_classifications = [
      {
        id: 1,
        name: "atest classification 1",
        description: "this is description of test classification 1"
      },
      {
        id: 2,
        name: "ctest classification 2",
        description: "this is description of test classification 2"
      },
      {
        id: 3,
        name: "btest classification 3",
        description: "this is description of test classification 3"
      },
      {
        id: 4,
        name: "dtest classification 4",
        description: "this is description of test classification 4"
      }
    ]
    let url = "https://www.google.com"
    let is_locked = null
    return {
      url: url,
      is_locked: is_locked,
      all_classifications: all_classifications,
      current_classifications: current_classifications,
    }
  }

  /****** LOGIC ******/
  componentDidUpdate(prevProps, prevState) {
    //console.log(this.state)
  }

  setURL( tmp_url, tmp_current_classifications, tmp_all_classifications, tmp_is_locked ) {
    this.setState( {
      url: tmp_url,
      allClassifications: tmp_all_classifications,
      currentClassifications: tmp_current_classifications,
      isLocked: ( tmp_is_locked ? tmp_is_locked : null ),
    })
  }

  unsetURL() {
    this.setState( {
      url: "",
      allClassifications: [],
      currentClassification: [],
      isLocked: null,
    })
  }

  /****** CONTENT ******/
  render() {
    let return_result = ""
    if( 0 === this.state.url.length ) {
      return_result = (
        <NLLURLInput
          setURL={this.setURL}
          unsetURL={this.unsetURL}
          currentURL={this.props.data}
        />
      )
    } else {
      return_result = (
        <NLLURLSubmission
          unsetURL={this.unsetURL}
          currentClassifications={this.state.currentClassifications}
          allClassifications={this.state.allClassifications}
          isLocked={this.state.isLocked}
          url={this.state.url}
          setSubmissionResult={this.props.setSubmissionResult}
        />
      )
    }
    return return_result
  }
}

NLLURL.propTypes = {
  setSubmissionResult: PropTypes.func,
  data: PropTypes.string,
}

export default withTranslation()(NLLURL)
