import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types'
import '../App.css';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'


class NLLSelectSubmissionType extends React.Component {
  render() {
    let return_result = ""
    return_result = (
      <Tabs
        id="controlled-tab-example"
        variant='tabs'
        activeKey={this.props.selectedSubmissionType}
        onSelect={ k => this.props.handleChange(k) }
      >
      {
        this.props.tabs.map(
          tab => (
            <Tab key={tab.key} eventKey={tab.key} title={tab.title}/>
          )
        )
      }
      </Tabs>
    )

    return return_result
  }
}

NLLSelectSubmissionType.propTypes = {
  tabs: PropTypes.array,
  handleChange: PropTypes.func,
  selectedSubmissionType: PropTypes.string,
}

export default NLLSelectSubmissionType
