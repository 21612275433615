import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import logo from './img/norton_logo.png'

//import translate from './common/NLLTranslate'
import { withTranslation } from 'react-i18next';
import NLLSelectLanguage from './common/NLLSelectLanguage'

class Header extends React.Component {
  //return ""   // uncomment to remove header
  render() {
    return (
      <header className="App-header">
        <div className="App-LogoSpace">
          <NLLSelectLanguage />
        </div>
        <a href="/" className="header-link">
          <img src={logo} className="App-logo-header" alt="logo" /><br />
          {this.props.t('app.heading-1')} <br />
          { this.props.t('app.heading') }
        </a>
      </header>
    )
  }
}

export default withTranslation()(Header)
