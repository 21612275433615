import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import { withTranslation, Trans } from 'react-i18next';

import info from "../img/info.png"

import Card from 'react-bootstrap/Card'

class NLLExplanation extends React.Component {

  render() {
    return (
      <Card>
        <Card.Header>
          <div className="Portal-FormExplanation">
            <img src={info} className="App-Help" alt="Info"/>
            <Trans i18nKey={this.props.translationKey} />
          </div>
        </Card.Header>
      </Card>
    )
  }
}

NLLExplanation.propTypes = {
  translationKey: PropTypes.string,
}

export default withTranslation()(NLLExplanation)
