import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Form from 'react-bootstrap/Form'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NLLHelp from "./NLLHelp"

import Card from 'react-bootstrap/Card'

class NLLInput extends React.Component {
  render() {
    return (
      <Card>
        <Card.Header>
          <Row className='Portal-FormRowText'>
            <Col md={11}>{this.props.header}</Col>
            <Col md={1}>
              <NLLHelp helpContent={this.props.helpContent} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <Form.Control
              as="input"
              placeholder={this.props.placeholder}
              name={this.props.name}
              key={this.props.name}
              onChange={this.props.handleChange}
              value={this.props.storedValue}
            />
            {
              this.props.error ? (
                <span className="Portal-FormError">
                  {this.props.error}
                </span>
              ) : ""
            }
          </Card.Text>
          {
            this.props.footer ? (
              <Card.Footer>
                {this.props.footer}
              </Card.Footer>
            ) : null
          }
        </Card.Body>
      </Card>
    )
  }
}

NLLInput.propTypes = {
  header: PropTypes.string,
  footer: PropTypes.object,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  storedValue: PropTypes.string,
  helpContent: PropTypes.object,
  error: PropTypes.string,
}

export default NLLInput
