import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import { withTranslation, Trans } from 'react-i18next';

class NLLLegal extends React.Component {

  render() {
    let privacy_policy_link = this.props.t("legal.privacyLink")
    return (
      <div className="Portal-FormLegal">
        <Trans i18nKey="legal.formText" /><a href={privacy_policy_link} target="_blank" rel="noreferrer noopener" > { this.props.t("legal.privacy") } </a>
      </div>
    )
  }
}

export default withTranslation()(NLLLegal)
