import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'

import { withTranslation } from 'react-i18next';

import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

import Container from 'react-bootstrap/Container'

class NLLSelectLanguage extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelectLanguage = this.handleSelectLanguage.bind(this)
  }

  handleSelectLanguage( event ) {
    this.props.i18n.changeLanguage( event )
  }

  render() {
    //console.log( "Language:" )
    //console.log( this.props.i18n.language )
    let language = "English"
    if( "no" === this.props.i18n.language ) {
      language = "Norsk"
    }
    return(   <Container className="App-LanguageSelectorContainer" /> )
    /*
    return (
      <Container className="App-LanguageSelectorContainer">
        <Nav className="App-LanguageNav" variant="pills" activeKey={this.props.i18n.language} onSelect={this.handleSelectLanguage}>
          <NavDropdown className="App-LanguageNavDropdown" title={language} id="nav-dropdown">
            <NavDropdown.Item className="App-LanguageNavItem" eventKey="en">English</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    )
    */
  }

}

export default withTranslation()( NLLSelectLanguage )
