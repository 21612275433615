import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import logofooter from './img/Gen.png'

//import translate from './common/NLLTranslate'
import { withTranslation } from 'react-i18next';

class Footer extends React.Component {

  render() {
    //return ""   // uncomment to remove footer

    let privacy_link = this.props.t("legal.privacyLink")
    let footer_text = this.props.t("legal.footer")
    let privacy_text = this.props.t("legal.privacyShort")
    let legal_link = this.props.t("legal.legalLink")
    let legal_text = this.props.t("legal.legalText")

    return (
      <div className="App-footer">
        <div className="App-LogoSpace" />
        <div className="App-footer-text">
          <a href="https://www.norton.com" className="logo-footer"><img src={logofooter} className="App-logo-footer" alt="logo" /></a>
          <p className="copyright-text">&nbsp; { footer_text } &nbsp;</p> <span>|</span>
          <a href={ legal_link } target="_blank" rel="noreferrer noopener" className="footer-link" > &nbsp; { legal_text } &nbsp;</a>
          <span>|</span>
          <a href={privacy_link} target="_blank" rel="noreferrer noopener" className="footer-link" > &nbsp; {privacy_text} </a>
        </div>
        <div className="App-LogoSpace"/>
      </div>
    )
  }
}

export default withTranslation()(Footer)
