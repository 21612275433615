import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NLLDropDown from '../common/NLLDropDown'
import NLLInput from '../common/NLLInput'
import NLLText from '../common/NLLText'
import NLLSubmit from '../common/NLLSubmit'
import NLLLegal from '../common/NLLLegal'
import NLLHelp from '../common/NLLHelp'
import portalConfig from '../portalConfig'

import { withTranslation, Trans } from 'react-i18next';

import ReCAPTCHA from "react-google-recaptcha";

import Spinner from 'react-bootstrap/Spinner'

import Card from 'react-bootstrap/Card'

import sw_safe from '../img/safeweb_safe.png'
import sw_warning from '../img/safeweb_warning.png'
import sw_dangerous from '../img/safeweb_dangerous.png'
import sw_untested from '../img/safeweb_untested.png'


class NLLURLSubmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_classification_id: false,
      new_classification_name: "",
      new_classification_description: "",
      description: "",
      recaptcha: "",
      userClickedSubmit: false,
      progress: false,
      submissionResult: false,
      submissionID: false,
      messageToUser: false,
      error: {
        new_classification: this.props.t("url.error.new_classification.blank"),
        description: this.props.t("url.error.description.blank"),
      }
    }

    this.handleNewClassificationChange = this.handleNewClassificationChange.bind(this)
    this.nop = this.nop.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this)

    this.handleUserClickSubmit = this.handleUserClickSubmit.bind(this)

  }

  /****** LOGIC ******/
  componentDidUpdate(prevProps, prevState) {
    //console.log(this.state)

    /*
      This is triggered when the user clicks on the submit button
      It is added like this to be able to show a progress bar or similar as soon as the user clicks on the button
    */
    if( this.state.userClickedSubmit !== prevState.userClickedSubmit ) {
      if( true === this.state.userClickedSubmit ) {
        this.handleSubmit()
      }
    }

    //if the change is that we submitted the form to the API and got a result
    if( this.state.submissionResult !== prevState.submissionResult ) {
      if( this.state.submissionResult ) {
        this.handleFinishedSubmission()
      } else {
        //we changed to False, so we are done!
      }
    }
  }

  formHasErrors() {
    for( let i in this.state.error ) {
      if( this.state.error[i] ) {
        return true
      }
    }
    if( !this.state.recaptcha ) {
      return true
    }
    return false
  }

  handleSubmit() {
    //console.log("Preparing submission data!")
    let data = {
      description: this.state.description,
      recaptcha: this.state.recaptcha,
      new_classification: {
        id: this.state.new_classification_id,
        name: this.state.new_classification_name,
      },
      url: this.props.url,
    }
    this.submit( data )
  }

  submit( data ) {
    //console.log("Submitting data!")

    //console.log( data )
    const axios = require("axios")

    let url = portalConfig["api_url"] + portalConfig["url_path"]
    //console.log("Submitting to: " + url)

    axios(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }
    ).then(
      resp => {
        /*
        console.log("ok")
        console.log(resp.status);
        console.log(resp.data);
        console.log(resp.headers);
        */
        if( 200 === resp.data.statusCode ) {
          this.setState(
            {
              submissionResult: {
                success: true,
                data: resp.data.body,
                error: false
              },
              submissionID: resp.data.session_id
            }
          )
        } else {
          this.setState(
            {
              submissionResult:  {
                success: false,
                data: false,
                error: "Wrong return code (" + resp.data.statusCode.toString() + ")"
              }
            }
          )
        }
      }
    ).catch( error => {
        //console.log('error', error);
        this.setState(
          {
            submissionResult:  {
              success: false,
              data: false,
              error: error
            }
          }
        )
      }
    )
  }

  // if the submission succeeded, this function will call the setSubmissionResult function which will unmount this component
  handleFinishedSubmission() {
    if( this.state.submissionResult.success ) {
      //console.log("URL OK")
      this.setSuccessfulSubmissionResult()
    } else {
      let error = (
        <div className="failedSubmission">
          <Trans i18nKey="url.failedMessage.header" />
        </div>
      )
      this.setFailedSubmissionResultMessage(error)
    }
  }

  setFailedSubmissionResultMessage( message ) {
    console.log( this.state.submissionResult.error )
    this.setState( {
      "userClickedSubmit": false,
      "messageToUser": message,
      "recaptcha": null,
      "submissionResult":  false,
      "progress": false,
    } )
  }

  setSuccessfulSubmissionResult() {
    let link = this.props.t("url.successMessage.link") + encodeURI( this.props.url )
    let success = (
      <div className="successfulSubmission">
        <div className="url-thankyou">
          <Trans i18nKey="url.successMessage.thankyou" />
        </div>
        <Container className="url-safeweb-container">
          <Row>
            <Col className="url-success-text">
              <Trans i18nKey="url.successMessage.pre" />
            </Col>
          </Row>
          <Row>&nbsp;</Row>
          <Row>
            <Col className="url-safeweb-icon-column">
              <img src={sw_safe} className="url-safeweb-icon" alt="safe" />
            </Col>
            <Col className="url-safeweb-icon-text-column">
              <Trans i18nKey="url.successMessage.safe" />
            </Col>
          </Row>
          <Row>
            <Col className="url-safeweb-icon-column">
              <img src={sw_warning} className="url-safeweb-icon" alt="warning" />
            </Col>
            <Col className="url-safeweb-icon-text-column">
              <Trans i18nKey="url.successMessage.warning" />
            </Col>
          </Row>
          <Row>
            <Col className="url-safeweb-icon-column">
              <img src={sw_dangerous} className="url-safeweb-icon" alt="dangerous" />
            </Col>
            <Col className="url-safeweb-icon-text-column">
              <Trans i18nKey="url.successMessage.dangerous" />
            </Col>
          </Row>
          <Row>
            <Col className="url-safeweb-icon-column">
              <img src={sw_untested} className="url-safeweb-icon" alt="untested" />
            </Col>
            <Col className="url-safeweb-icon-text-column">
              <Trans i18nKey="url.successMessage.untested" />
            </Col>
          </Row>
          <Row>&nbsp;</Row>
          <Row>
            <Col className="url-success-text">
              <Trans i18nKey="url.successMessage.post" />
              <a href={link}>{link}</a>
            </Col>
          </Row>
        </Container>
      </div>
    )
    this.props.setSubmissionResult(success)
    this.setState( { "userClickedSubmit": false } ) // this will not really run
  }

  getSubmissionProgress() {
    const spinner = (
      <Container className="Portal-SubmissionSpinnerContainer">
        <Row>
          <Spinner
             animation="grow"
             size='lg'
             role="status"
             className="Portal-SubmissionSpinner"
          />
        </Row>
        <Row className="Portal-SubmittingText">
          {this.props.t('submission.submissionPending')}
          <br />
          ...
        </Row>
      </Container>
    )
    return (
      <div>
          {spinner}
      </div>
    )
  }

  /****** FORM HANDLERS ******/
  handleDescriptionChange(event) {
    let tmp_description = event.target.value
    let tmp_error = null
    if( 950 < tmp_description.length ) {
      tmp_error = this.props.t("url.error.description.tooLong")
    }
    if( 0 === tmp_description.length ) {
      tmp_error = this.props.t("url.error.description.blank")
    }
    this.setState(
      {
        description: tmp_description,
        error: {
          ...this.state.error,
          description: tmp_error
        }
      }
    )
  }

  handleRecaptchaChange(value) {
    this.setState( { recaptcha: value } )
  }

  handleNewClassificationChange(event) {
    let tmp_classification_id = parseInt( event.target.value )
    let tmp_classification_name = false
    let tmp_classification_description = ""
    //console.log( this.props.allClassifications )
    let tmp_error = null
    //no need to submit the same classification as the current unless the current contains several classifications and we want to remove one
    if( 1 === this.props.currentClassifications.length ) {
      if( tmp_classification_id === parseInt( this.props.currentClassifications[0]['id'] ) ) {
        tmp_error = this.props.t("url.error.new_classification.blank")
      }
    }
    if( !tmp_error ) {
      //console.log( "no error")
      for( let i in this.props.allClassifications ) {
        //console.log( i )
        if( tmp_classification_id === parseInt( this.props.allClassifications[i]['id'] ) ) {
          //console.log( "ok" )
          tmp_classification_name = this.props.allClassifications[i]['name']
          tmp_classification_description = this.props.allClassifications[i]['description']
          break
        }
      }
    }
    this.setState(
      {
        new_classification_id: tmp_classification_id,
        new_classification_name: tmp_classification_name,
        new_classification_description: tmp_classification_description,
        error: {
          ...this.state.error,
          new_classification: tmp_error
        }
      }
    )
  }

  handleUserClickSubmit(event) {
    //console.log("User clicked submit!")
    if( !this.formHasErrors() ) {
      this.setState( { "userClickedSubmit": true } )
    }
  }

  nop() {}

  /****** CONTENT ******/
  inputURL() {
    return (
      <NLLInput
        placeholder={this.props.t('url.input.url.placeholder')}
        name="url"
        key="url"
        header={this.props.t('url.input.url.header')}
        storedValue={this.props.url}
        handleChange={this.nop}
      />
    )
  }

  inputNewClassification() {
    let options = []
    let classifications = []
    for( let i in this.props.allClassifications ) {
      let translate_name_key = "url.input.new.id." + this.props.allClassifications[i]['id'] + ".name"
      classifications.push(
        {
          "id": this.props.allClassifications[i]["id"],
          "name": this.props.t( [ translate_name_key, this.props.allClassifications[i]['name'] ]),
          "description": this.props.allClassifications[i]["description"],
        }
      )
    }
    classifications = classifications.sort(
      function( a, b ) {
        if( a.name < b.name ) {
          return -1;
        }
        if( a.name > b.name ) {
          return 1;
        }
        return 0;
      }
    )
    for( let i in classifications ) {
      //let's not even display the current classification as an option if there is only one current classification. If there are more than 1 current classification, we might want to change it still.
      if( ( 1 === this.props.currentClassifications.length ) && ( parseInt( classifications[i]['id'] ) === parseInt( this.props.currentClassifications[0]['id'] ) ) ) {
        continue;
      }
      options.push( { id: classifications[i]['id'], text: classifications[i]['name'] } )
    }
    let header = (
      <div className="inputHeader" key="newClassificationHeader">
        <Trans i18nKey="url.input.new.header" />
      </div>
    )
    // use the category id as the translate key, default to the returned description from 3rd party
    let translate_key = ""
    if( false !== this.state.new_classification_id ) {
        translate_key = "url.input.new.id." + this.state.new_classification_id + ".description"
    }
    let footer = (
      <Trans i18nKey={translate_key}>
        { this.state.new_classification_description }
      </Trans>
    )
    return (
      <NLLDropDown
        name="new_classification"
        key="new_classification"
        options={options}
        selected={ this.state.new_classification_id }
        header={header}
        handleChange={this.handleNewClassificationChange}
        error={this.state.error.new_classification}
        footer={footer}
        pleaseSelectString={this.props.t('url.input.new.pleaseSelect')}
      />
    )
  }

  getOldClassifications() {
    let classifications = []
    for( let i in this.props.currentClassifications ) {
      let description = ""
      for( let j in this.props.allClassifications ) {
        if( parseInt( this.props.allClassifications[j]["id"] ) === parseInt( this.props.currentClassifications[i]["id"] ) ) {
          let translate_description_key = "url.input.new.id." + this.props.allClassifications[j]['id'] + ".description"
          description = this.props.t( [ translate_description_key, this.props.allClassifications[j]['description'] ])
          break
        }
      }
      let translate_name_key = "url.input.new.id." + this.props.currentClassifications[i]['id'] + ".name"
      let key = "old_classification_" + i
      let classification_name = this.props.t( [ translate_name_key, this.props.currentClassifications[i]['name'] ])
      let help_content = {
        "header": classification_name,
        "body": description,
        "footer": null,
      }
      classifications.push(
        <Row className='oldClassification' key={key}>
          <Col md={11}>{ classification_name }</Col>
          <Col md={1}>
            <NLLHelp helpContent={help_content} />
          </Col>
        </Row>
      )
    }
    let classification = (
      <Card>
        <Card.Header>
          {this.props.t('url.input.old.header')}
        </Card.Header>
        <Card.Body>
          {classifications}
        </Card.Body>
      </Card>
    )
    return classification
  }

  inputDescription(){
    let header = (
      <div className="inputHeader">
        <Trans i18nKey="url.input.description.header" />
      </div>
    )
    return (
      <NLLText
        name="description"
        key="description"
        header={header}
        handleChange={this.handleDescriptionChange}
        storedValue={this.state.description}
        error={this.state.error.description}
      />
    )
  }

  render() {
    let return_result = ""
    if( true === this.state.userClickedSubmit ) {
      return_result = this.getSubmissionProgress()
    } else {
      let submission = ""
      if( ! this.props.isLocked ) {
        submission = [
          <Row className="Portal-RowInputFileUrlHash" key="NewClassification">
            <Col md={12}>
              {this.inputNewClassification()}
            </Col>
          </Row>,
          <Row className="Portal-RowInputDescription" key="Description">
            <Col md={12}>
              {this.inputDescription()}
            </Col>
          </Row>,
          <Row className="Portal-RowLegal" key="Legal">
            <Col md={12}>
              <NLLLegal />
            </Col>
          </Row>,
          <Row className="Portal-RowReCaptcha" key="ReCaptcha">
            <Col md={12}>
              <ReCAPTCHA
                sitekey="6LeQTcUUAAAAAJJ28uHBNLl6ZXyZ74F4qt4N5v2e"
                onChange={this.handleRecaptchaChange}
                key="captcha"
              />
            </Col>
          </Row>,
          <Row className="Portal-RowSubmit" key="Submit">
            <Col md={12}>
              <NLLSubmit
                isDisabled={false}
                key="submit"
                name="submit"
                missingText="Missing values"
                submitText={this.props.t('submission.submitButton')}
                handleClick={this.handleUserClickSubmit}
                className="Portal-SubmitButton"
              />
            </Col>
          </Row>
        ]
      } else if( "special" === this.props.isLocked ) {
        submission = (
          <Row className="Portal-RowInputFileUrlHash">
            <Col md={12}>
              <div className="Portal-LockedURL">
                <Trans i18nKey="url.input.special.message" />
              </div>
            </Col>
          </Row>
        )
      } else {
        submission = (
          <Row className="Portal-RowInputFileUrlHash">
            <Col md={12}>
              <div className="Portal-LockedURL">
                <Trans i18nKey="url.input.locked.message" />
              </div>
            </Col>
          </Row>
        )
      }
      return_result = (
        <Container className="Portal-FormContainer" key="NLLPortalFormContainer">
          <Row className="Portal-MessageToUser">
            <Col md={12}>
              {this.state.messageToUser ? this.state.messageToUser : false}
            </Col>
          </Row>
          <Row className="Portal-RowInputFileUrlHash">
            <Col md={12}>
              {this.inputURL()}
            </Col>
          </Row>
          <Row className="Portal-RowInputFileUrlHash">
            <Col md={12}>
              {this.getOldClassifications()}
            </Col>
          </Row>
          { submission }
          <Row className="Portal-RowSubmit">
            <Col md={12}>
              <NLLSubmit
                isDisabled={false}
                key="backt"
                name="Go back"
                missingText="Missing values"
                submitText={this.props.t('submission.goBackButton')}
                handleClick={this.props.unsetURL}
                className="Portal-GoBackButton"
              />
            </Col>
          </Row>
        </Container>
      )
    }
    return return_result
  }

}

NLLURLSubmission.propTypes = {
  setSubmissionResult: PropTypes.func,
  currentClassifications: PropTypes.array,
  allClassifications: PropTypes.array,
  isLocked: PropTypes.string,
  url: PropTypes.string,
  unsetURL: PropTypes.func,
}

export default withTranslation()(NLLURLSubmission)
