import React from 'react'
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NLLFP from './FP/NLLFP'
import NLLFN from './FN/NLLFN'
import NLLURL from './URL/NLLURL'

import NLLSelectSubmissionType from './common/NLLSelectSubmissionType'


class NLLPortal extends React.Component {
  constructor(props) {
    super(props);
    let type = this.validateProps()
    this.state = {
      selectedSubmissionType: type,
      submissionResult: false
    }
    this.handleChangeSubmissionType = this.handleChangeSubmissionType.bind(this)
    this.setSubmissionResult = this.setSubmissionResult.bind(this)
  }

  handleChangeSubmissionType(type) {
    this.setState({ selectedSubmissionType: type } )
  }

  setSubmissionResult( result ) {
    this.setState( {
      submissionResult: result
    } )
  }

  validateProps() {

    let return_result = ""
    let types = [ "URL", "FN", "FP" ]
    if( types.includes( this.props.type ) ) {
      return_result = this.props.type
    }
    return return_result
  }

  getForm() {
    let form = ""
    if( "FP" === this.state.selectedSubmissionType ) {
      form = (
        <NLLFP
          setSubmissionResult = {this.setSubmissionResult}
          data = {this.props.data}
        />
      )
    } else if ( "FN" === this.state.selectedSubmissionType ) {
      form = (
        <NLLFN
          setSubmissionResult = {this.setSubmissionResult}
          data = {this.props.data}
        />
      )
    } else if ( "URL" === this.state.selectedSubmissionType ) {
      form = (
        <NLLURL
          setSubmissionResult = {this.setSubmissionResult}
          data = {this.props.data}
        />
      )
    }
    const { t } = this.props;
    let tabs = [
        {
          "key": "FP",
          "title": t('app.tabs.fp')
        },
        {
          "key": "FN",
          "title": t('app.tabs.fn')
        },
        {
          "key": "URL",
          "title": t('app.tabs.url')
        }
    ]
    let return_result = (
      <Container className="App-PortalContainer" key="NLLPortal">
        <Row className="App-TabRow">
          <Col md={12}>
            <NLLSelectSubmissionType
              handleChange={this.handleChangeSubmissionType}
              selectedSubmissionType={this.state.selectedSubmissionType}
              tabs={tabs}
            />
          </Col>
        </Row>
        <Row className="App-FormRow">
          <Col md={12}>
            {form}
          </Col>
        </Row>
      </Container>
    )
    return return_result
  }

  getResultPage() {
    return this.state.submissionResult
  }

  render() {
    if( false === this.state.submissionResult ) {
      return this.getForm()
    } else {
      return (
        <div className="App-SubmissionSuccessResultText">
          {this.getResultPage()}
        </div>
      )
    }
  }
}

NLLPortal.propTypes = {
  type: PropTypes.string,
  data: PropTypes.string,
}

export default withTranslation()(NLLPortal)
