import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NLLInput from '../common/NLLInput'
import NLLSubmit from '../common/NLLSubmit'
import NLLLegal from '../common/NLLLegal'
import NLLExplanation from '../common/NLLExplanation'
import portalConfig from '../portalConfig'

import { withTranslation, Trans } from 'react-i18next';

import ReCAPTCHA from "react-google-recaptcha";

import Validator from 'jsonschema'

import Spinner from 'react-bootstrap/Spinner'

class NLLCheck extends React.Component {
  constructor(props) {
    super(props);
    let validated_submission_id = this.validateSubmissionID( this.props.currentSubmissionID )
    this.state = {
      submission_id: validated_submission_id['submission_id'],
      recaptcha: null,
      userClickedSubmit: false,
      progress: false,
      submissionResult: false,
      showResult: false,
      messageToUser: false,
      error: {
        submission_id: validated_submission_id["error"],
      }
    }

    this.handleSubmissionIDChange = this.handleSubmissionIDChange.bind(this)
    this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this)
    this.handleUserClickSubmit = this.handleUserClickSubmit.bind(this)

  }

  /****** LOGIC ******/
  componentDidUpdate(prevProps, prevState) {
    //console.log(this.state)

    /*
      This is triggered when the user clicks on the submit button
      It is added like this to be able to show a progress bar or similar as soon as the user clicks on the button
    */
    if( this.state.userClickedSubmit !== prevState.userClickedSubmit ) {
      if( true === this.state.userClickedSubmit ) {
        this.handleSubmit()
      }
    }

    //if the change is that we submitted the form to the API and got a result
    if( this.state.submissionResult !== prevState.submissionResult ) {
      if( this.state.submissionResult ) {
        this.handleFinishedSubmission()
      } else {
        //we changed to False, so we are done!
      }
    }
  }

  formHasErrors() {
    for( let i in this.state.error ) {
      if( this.state.error[i] ) {
        return true
      }
    }
    if( !this.state.recaptcha ) {
      return true
    }
    return false
  }

  handleSubmit() {
    //console.log("Preparing submission data!")
    let data = {
      recaptcha: this.state.recaptcha,
      session_id: this.state.submission_id,
    }
    this.submit( data )
  }

  submit( data ) {
    //console.log("Submitting data!")

    //console.log( data )

    const axios = require("axios")

    let url = portalConfig["api_url"] + portalConfig["check_submission_path"]
    //console.log("Submitting to: " + url)

    axios(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }
    ).then(
      resp => {

        //console.log("ok")
        //console.log(resp.status);
        //console.log(resp.data);
        //console.log(resp.headers);

        if( 200 === resp.data.statusCode ) {
          this.setState(
            {
              submissionResult: {
                success: true,
                data: resp.data.body,
                error: false
              }
            }
          )
        } else {
          this.setState(
            {
              submissionResult:  {
                success: false,
                data: false,
                error: "Wrong return code (" + resp.data.statusCode.toString() + ")"
              }
            }
          )
        }
      }
    ).catch( error => {
        //console.log('error', error);
        this.setState(
          {
            submissionResult:  {
              success: false,
              data: false,
              error: error
            }
          }
        )
      }
    )
  }

  // if the submission succeeded, this function will call the setSubmissionResult function which will unmount this component
  handleFinishedSubmission() {
    if( this.state.submissionResult.success ) {
      //console.log("URL OK")
      this.setSuccessfulSubmissionResult()
    } else {
      let error = (
        <div className="failedSubmission">
          <Trans i18nKey="check_submission.failedMessage.header" />
        </div>
      )
      this.setFailedSubmissionResultMessage(error)
    }
  }

  setFailedSubmissionResultMessage( message ) {
    //console.log( this.state.submissionResult.error )
    this.setState( {
      "userClickedSubmit": false,
      "messageToUser": message,
      "recaptcha": null,
      "submissionResult":  false,
      "progress": false,
    } )
  }

  setSuccessfulSubmissionResult() {
    let json_data = JSON.parse( this.state.submissionResult.data )
    //console.log( json_data )
    this.setState( { "userClickedSubmit": false, "showResult": true } ) // this will not really run
  }

  getSubmissionProgress() {
    const spinner = (
      <Container className="Portal-SubmissionSpinnerContainer">
        <Row>
          <Spinner
             animation="grow"
             size='lg'
             role="status"
             className="Portal-SubmissionSpinner"
          />
        </Row>
        <Row className="Portal-SubmittingText">
          {this.props.t('submission.submissionPending')}
          <br />
          ...
        </Row>
      </Container>
    )
    return (
      <div>
          {spinner}
      </div>
    )
  }

  /****** FORM HANDLERS ******/
  handleRecaptchaChange(value) {
    this.setState( { recaptcha: value } )
  }

  ltrim(str) {
    if(!str) return str;
      return str.replace(/^\s+/g, '');
  }

  validateSubmissionID( submission_id ) {
    let tmp_submission_id = submission_id ? this.ltrim( submission_id ) : ""
    let return_value = {
      "submission_id": tmp_submission_id,
      "error": null
    }
    let tmp_error = null
    let submission_id_schema = {
      "type": "string",
      "pattern": "^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$"
    }
    if( ! tmp_submission_id.length ) {
      tmp_error = this.props.t("check_submission.error.submission_id.blank")
    } else if( Validator.validate( tmp_submission_id, submission_id_schema ).errors.length ) {
      tmp_error = this.props.t("check_submission.error.submission_id.invalid")
    }
    return_value["error"] = tmp_error
    return return_value
  }

  handleSubmissionIDChange(event) {
    let validated_submission_id = this.validateSubmissionID( event.target.value )
    this.setState(
      {
        submission_id: validated_submission_id["submission_id"],
        error: {
          ...this.state.error,
          submission_id: validated_submission_id["error"]
        }
      }
    )
  }

  handleUserClickSubmit(event) {
    //console.log("User clicked submit!")
    if( !this.formHasErrors() ) {
      this.setState( { "userClickedSubmit": true } )
    }
  }

  /****** CONTENT ******/
  inputSubmissionID() {
    return (
      <NLLInput
        placeholder={this.props.t('check_submission.input.submission_id.placeholder')}
        name="submission_id"
        key="submission_id"
        header={this.props.t('check_submission.input.submission_id.header')}
        handleChange={this.handleSubmissionIDChange}
        storedValue={this.state.submission_id}
        error={this.state.error.submission_id}
      />
    )
  }

  getSubmissionDetailsTranslation( submission_type, status, details ) {
    let return_value = details
    let support_link = "https://support.norton.com"
    let safeweb_link = "https://safeweb.norton.com"
    if( "Failed" === status ) {
      return_value = (
        <div>
          <Trans i18nKey='check_submission.successMessage.submission_details.failed' />
          <a href={support_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosupport' ) } </a>
        </div>
      )
    } else {
      switch( submission_type ) {
        case "FP": {
          switch( details ) {
            case "Additional information needed": {
              return_value = (
                <div>
                  <Trans i18nKey='check_submission.successMessage.submission_details.fp.additional' />
                  <a href={support_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosupport' ) } </a>
                </div>
              )
              break
            }
            case "Detection was removed": {
              return_value = (
                <div>
                  <Trans i18nKey='check_submission.successMessage.submission_details.fp.removed' />
                  <a href={support_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosupport' ) } </a>
                </div>
              )
              break
            }
            case "URL was processed": {
              return_value = (
                <div>
                  <Trans i18nKey='check_submission.successMessage.submission_details.fp.URLprocessed' />
                  <a href={safeweb_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosafeweb' ) } </a>
                </div>
              )
              break
            }
            case "File reclassified": {
              return_value = (
                <div>
                  <Trans i18nKey='check_submission.successMessage.submission_details.fp.reclassified' />
                  <a href={support_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosupport' ) } </a>
                </div>
              )
              break
            }
            default: {
              return_value = details
            }
          }
          break
        }
        case "FN": {
          switch( details ) {
            case "Case has been prosessed and closed": {
              return_value = (
                <div>
                  <Trans i18nKey='check_submission.successMessage.submission_details.fn.processed' />
                  <a href={support_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosupport' ) } </a>
                </div>
              )
              break
            }
            default: {
              return_value = details
            }
          }
          break
        }
        case "URL": {
          switch( details ) {
            case "Unchanged": {
              return_value = (
                <div>
                  <Trans i18nKey='check_submission.successMessage.submission_details.url.unchanged' />
                  <a href={support_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosupport' ) } </a>
                </div>
              )
              break
            }
            case "Changed": {
              return_value = (
                <div>
                  <Trans i18nKey='check_submission.successMessage.submission_details.url.changed' />
                  <a href={safeweb_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosafeweb' ) } </a>
                </div>
              )
              break
            }
            case "Unknown": {
              return_value = (
                <div>
                  <Trans i18nKey='check_submission.successMessage.submission_details.url.unknown' />
                  <a href={safeweb_link}> { this.props.t( 'check_submission.successMessage.submission_details.gotosafeweb' ) } </a>
                </div>
              )
              break
            }
            default: {
              return_value = details
            }
          }
          break
        }
        default: {
          return_value = details
        }
      }
    }
    return return_value
  }

  showSuccessInfo() {
    let return_result = ""
    let json_data = JSON.parse( this.state.submissionResult.data )
    let update_time = new Date( ( parseInt( json_data["timestamp"] ) * 1000 ) )
    let submission_type_translation_key = 'check_submission.successMessage.submission_type.' + json_data["submission_type"]
    let submission_status_translation_key = 'check_submission.successMessage.submission_status.' + json_data["submission_status"]
    let submission_details = this.getSubmissionDetailsTranslation( json_data["submission_type"], json_data["submission_status"], String( json_data["submission_details"] ) )

    return_result = (
      <Container className="Portal-FormContainer" key="NLLPortalFormContainer">
        <Row className="Portal-CheckSuccess">
          <Col md={4}>
            <Trans i18nKey='check_submission.successMessage.submission_id.header'>
              Submission ID:
            </Trans>
          </Col>
          <Col md={8}>
            { this.state.submission_id }
          </Col>
        </Row>
        <Row className="Portal-CheckSuccess">
          <Col md={4}>
            <Trans i18nKey='check_submission.successMessage.submission_type.header'>
              Submission type:
            </Trans>
          </Col>
          <Col md={8}>
            <Trans i18nKey={submission_type_translation_key}>
              { json_data["submission_type"] }
            </Trans>
          </Col>
        </Row>
        <Row className="Portal-CheckSuccess">
          <Col md={4}>
            <Trans i18nKey='check_submission.successMessage.submission_status.header'>
              Submission status:
            </Trans>
          </Col>
          <Col md={8}>
            <Trans i18nKey={submission_status_translation_key}>
              { json_data["submission_status"] }
            </Trans>
          </Col>
        </Row>
        <Row className="Portal-CheckSuccess">
          <Col md={4}>
            <Trans i18nKey='check_submission.successMessage.submission_details.header'>
              Details:
            </Trans>
          </Col>
          <Col md={8}>
            { submission_details }
          </Col>
        </Row>
        <Row className="Portal-CheckSuccess">
          <Col md={4}>
            <Trans i18nKey='check_submission.successMessage.submission_updated.header'>
              Updated:
            </Trans>
          </Col>
          <Col md={8}>
            { update_time.toUTCString() }
          </Col>
        </Row>
      </Container>
    )
    return return_result
  }

  render() {
    let return_result = ""
    if( true === this.state.showResult ) {
      return_result = this.showSuccessInfo()
    } else if( true === this.state.userClickedSubmit ) {
      return_result = this.getSubmissionProgress()
    } else {
      return_result = (
        <Container className="Portal-FormContainer" key="NLLPortalFormContainer">
          <Row className="Portal-MessageToUser">
            <Col md={12}>
              {this.state.messageToUser ? this.state.messageToUser : false}
            </Col>
          </Row>
          <Row className="Portal-FormRowText">
            <Col md={12}>
              <NLLExplanation
                translationKey="check_submission.input.explanation"
              />
            </Col>
          </Row>
          <Row className="Portal-RowInputFileUrlHash">
            <Col md={12}>
              {this.inputSubmissionID()}
            </Col>
          </Row>
          <Row className="Portal-RowLegal">
            <Col md={12}>
              <NLLLegal />
            </Col>
          </Row>
          <Row className="Portal-RowReCaptcha">
            <Col md={12}>
              <ReCAPTCHA
                sitekey="6LeQTcUUAAAAAJJ28uHBNLl6ZXyZ74F4qt4N5v2e"
                onChange={this.handleRecaptchaChange}
                key="captcha"
              />
            </Col>
          </Row>
          <Row className="Portal-RowSubmit">
            <Col md={12}>
              <NLLSubmit
                isDisabled={false}
                key="submit"
                name="submit"
                missingText="Missing values"
                submitText={this.props.t('submission.submitButton')}
                handleClick={this.handleUserClickSubmit}
                className="Portal-SubmitButton"
              />
            </Col>
          </Row>
        </Container>
      )
    }
    return return_result
  }

}

NLLCheck.propTypes = {
  currentSubmissionID: PropTypes.string,
}

export default withTranslation()(NLLCheck)
