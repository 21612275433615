import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Modal from 'react-bootstrap/Modal'
import questionmark from "../img/questionmark.png"

class NLLHelp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showHelp: false,
    }
    this.showHelp = this.showHelp.bind(this)
    this.hideHelp = this.hideHelp.bind(this)
  }

  showHelp() {
    this.setState( { showHelp: true } )
  }

  hideHelp() {
    this.setState( { showHelp: false } )
  }

  render() {
    let help = ""
    if( this.props.helpContent ) {
      let header = ""
      let body = ""
      let footer = ""
      if( this.props.helpContent.header ) {
        header = (
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.props.helpContent.header}
            </Modal.Title>
          </Modal.Header>
        )
      }
      if( this.props.helpContent.body ) {
        body = (
          <Modal.Body>
            {this.props.helpContent.body}
          </Modal.Body>
        )
      }
      if( this.props.helpContent.footer ) {
        footer = (
          <Modal.Footer>
            {this.props.helpContent.footer}
          </Modal.Footer>
        )
      }
      help = (
        <div>
          <img src={questionmark} className="App-Help" alt="help" onClick={this.showHelp} />
          <Modal
            show={ this.state.showHelp }
            onHide={ this.hideHelp }
            dialogClassName="Portal-FormHelpModal"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            { header }
            { body }
            { footer }
          </Modal>
        </div>
      )
    }
    return (
      <div>
        { help }
      </div>
    )
  }
}

NLLHelp.propTypes = {
  helpContent: PropTypes.object,
}

export default NLLHelp
