import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Form from 'react-bootstrap/Form'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NLLHelp from "./NLLHelp"

import Card from 'react-bootstrap/Card'

class NLLRadio extends React.Component {

  doNothing() {
    // just to do nothing if a radiobutton is already checked
  }

  getOption(id, text, helpContent) {
    let checked = ( this.props.selected === id )
    if( this.props.selected && !checked ) { // if the user has selected another option already
      return("")                            // then do not show this option
    }
    let onClick = () => this.props.handleChange( id )
    if( this.props.selected ) { // if a button is already selected, we do not allow this to be pushed again
      onClick = () => this.doNothing()
    }
    let rowId = "custom-row-" + id
    let divId = "custom-div-" + id
    let radioId = "custom-radio-" + id
    return (
      <Row className='Portal-FormRowRadio' key={rowId}>
        <Col md={11}>
          <div key={divId} onClick={onClick}>
            <Form.Check
              custom
              type="radio"
              defaultChecked={checked}
              label={text}
              key={radioId}
              id={id}
            />
          </div>
        </Col>
        <Col md={1}>
          <NLLHelp helpContent={ helpContent } />
        </Col>
      </Row>
    )
  }
  render() {
    return (
      <Card>
        <Card.Header>
          <Row className='Portal-FormRowText'>
            <Col md={11}>{this.props.header}</Col>
            <Col md={1}>
              <NLLHelp helpContent={this.props.helpContent} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Container className="Portal-FormRadioContainer">
            {
              this.props.options.map(
                option => (
                  this.getOption(option.id, option.text, option.helpContent)
                )
              )
            }
          </Container>
          {
            this.props.footer ? (
              <Card.Footer>
                {this.props.footer}
              </Card.Footer>
            ) : null
          }
        </Card.Body>
      </Card>
    )
  }
}

NLLRadio.propTypes = {
  options: PropTypes.array,
  header: PropTypes.any,
  footer: PropTypes.object,
  handleChange: PropTypes.func,
  selected: PropTypes.string,
  name: PropTypes.string,
  helpContent: PropTypes.object,
}

export default NLLRadio
