import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Form from 'react-bootstrap/Form'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NLLHelp from "./NLLHelp"

import Card from 'react-bootstrap/Card'

class NLLDropDown extends React.Component {
  render() {
    let tmp_options = this.props.options
    let selected = this.props.selected
    if( !selected ) {
      tmp_options = [ { id:"-1", extra:true, text: this.props.pleaseSelectString } ].concat( tmp_options )
      selected = "-1"
    }
    return (
      <Card>
        <Card.Header>
          <Row className='Portal-FormRowText'>
            <Col md={11}>{this.props.header}</Col>
            <Col md={1}>
              <NLLHelp helpContent={this.props.helpContent} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form.Control
            as="select"
            value={selected}
            onChange={ (event) => this.props.handleChange( event ) }
            key={this.props.name}
          >
            {
              tmp_options.map(
                option => (
                  <option value={option.id} key={option.id} disabled={option.extra?option.extra:false}>{option.text}</option>
                )
              )
            }
          </Form.Control>
          {
            this.props.error ? (
              <span className="Portal-FormError">
                {this.props.error}
              </span>
            ) : ""
          }
          {
            this.props.footer ? (
              <Card.Footer>
                {this.props.footer}
              </Card.Footer>
            ) : null
          }
        </Card.Body>
      </Card>
    )
  }
}

NLLDropDown.propTypes = {
  options: PropTypes.array,
  header: PropTypes.object,
  footer: PropTypes.object,
  handleChange: PropTypes.func,
  selected: PropTypes.any,
  name: PropTypes.string,
  helpContent: PropTypes.object,
  error: PropTypes.string,
  pleaseSelectString: PropTypes.string,
}

export default NLLDropDown
