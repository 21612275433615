import React, {Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import NLLPortal from './NLLPortal'
import NLLCheck from './Check/NLLCheck'
import logo from './img/norton_logo.png'
import Header from './header.jsx'
import Footer from './footer.jsx'
import Banner from './banner.jsx'


import {
  Routes,
  Route,
  useLocation
} from "react-router-dom"


// loading component for suspense fallback
const Loader = () => (
  <header className="App-header">
    <div className="App-LogoSpace"/>
    <img src={logo} className="App-logo" alt="logo" />
    <div className="App-LogoSpace"/>
      <div className="page-loader-container">
    <div className="page-loader"/>
</div>
    <hr className="App-hr" />
  </header>
)

// A custom hook that builds on useLocation to parse the query string
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function NLLRouter() {
  let query = useQuery()
  let my_type = query.get("type")
  //console.log( "type: " + query.get("type") )
  //console.log( "data: " + query.get("data") )
  if( "CHECK" === my_type ) {
    return (
      <Routes>
        <Route path="/">
          <NLLCheck
            currentSubmissionID={query.get("submission_id")}
          />
        </Route>
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route path="/">
          <NLLPortal
            type={my_type}
            data={query.get("data")}
          />
        </Route>
      </Routes>
    )
  }
}

function App() {
    return (
      <Suspense fallback={<Loader />}>
        <head>
          <link href='https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap' rel='stylesheet' type='text/css' />
          <link href='https://fonts.googleapis.com/css?family=Roboto&display=swap' rel='stylesheet' type='text/css' />
        </head>
        <div className="App">
	  <Header />
	  <Banner />
          
          <Footer />
        </div>
      </Suspense>
    )
}

export default App


