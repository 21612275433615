import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NLLRadio from '../common/NLLRadio'
import NLLInput from '../common/NLLInput'
import NLLText from '../common/NLLText'
import NLLSubmit from '../common/NLLSubmit'
import NLLFile from '../common/NLLFile'
import NLLLegal from '../common/NLLLegal'
import NLLExplanation from '../common/NLLExplanation'
import portalConfig from '../portalConfig'

import { withTranslation, Trans } from 'react-i18next';

import ReCAPTCHA from "react-google-recaptcha";

import Spinner from 'react-bootstrap/Spinner'

class NLLFP extends React.Component {
  constructor(props) {
    super(props);
    let currentData = this.validateData( this.props.data )
    this.state = {
      step: currentData["step"],
      selectedA: currentData["inputA"]["value"],
      selectedB: currentData["inputB"]["value"],
      selectedD: currentData["type"]["value"],
      selectedType: currentData["type"]["value"],
      selectedFile: null,
      url: currentData["url"]["value"],
      hash: currentData["hash"]["value"],
      software: currentData["software"]["value"],
      detection: currentData["detection"]["value"],
      description: currentData["description"]["value"],
      recaptcha: null,

      progress: false,
      userClickedSubmit: false,
      messageToUser: false,
      submitFileResult: false,
      submissionResult: false,
      submissionID: false,
      error: {
        url: currentData["url"]["error"],
        hash: currentData["hash"]["error"],
        file: null,
        software: currentData["software"]["error"],
        detection: currentData["detection"]["error"],
        description: currentData["description"]["error"],
      }
    };

    this.inputAHandleChange = this.inputAHandleChange.bind(this);
    this.inputBHandleChange = this.inputBHandleChange.bind(this);
    this.inputDHandleChange = this.inputDHandleChange.bind(this);

    this.handleFileSelectChange = this.handleFileSelectChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleHashChange = this.handleHashChange.bind(this);

    this.handleSoftwareChange = this.handleSoftwareChange.bind(this);
    this.handleDetectionChange = this.handleDetectionChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this);

    this.handleUserClickSubmit = this.handleUserClickSubmit.bind(this);

    this.goBack = this.goBack.bind(this);
  }

  /****** LOGIC ******/
  formHasErrors() {
    for( let i in this.state.error ) {
      if( this.state.error[i] ) {
        return true
      }
    }
    if( !this.state.recaptcha ) {
      return true
    }
    return false
  }

  handleSubmit() {
    //console.log("Preparing submission data!")
    let data = {
      type: "FP",
      recaptcha: this.state.recaptcha,
      detection: this.state.detection,
      software: this.state.software,
    }
    let tmpDescription = this.state.description
    tmpDescription += "\n" + this.state.selectedA
    tmpDescription += "\n" + this.state.selectedB
    tmpDescription += "\n" + this.state.selectedD
    data = {
      ...data,
      description: tmpDescription,
    }
    if( "FILE" === this.state.selectedType ) {
      data = {
        ...data,
        filename: this.state.selectedFile.name,
        filesize: this.state.selectedFile.size,
        filetype: this.state.selectedFile.type,
      }
    } else if( "URL" === this.state.selectedType ) {
      data = {
        ...data,
        url: this.state.url,
      }
    } else if( "HASH" === this.state.selectedType ) {
      data = {
        ...data,
        hash: this.state.hash,
      }
    }
    this.submit( data )
  }

  submit( data ) {
    //console.log("Submitting data!")

    //console.log( data )
    const axios = require("axios")
    let url = portalConfig["api_url"] + portalConfig["fp_path"]
    //console.log("Submitting to: " + url)
    axios(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }
    ).then(
      resp => {
        /*
        console.log("ok")
        console.log(resp.status);
        console.log(resp.data);
        console.log(resp.headers);
        */
        if( 200 === resp.data.statusCode ) {
          this.setState(
            {
              submissionResult: {
                success: true,
                data: resp.data.body,
                error: false
              },
              submissionID: resp.data.session_id
            }
          )
        } else {
          this.setState(
            {
              submissionResult:  {
                success: false,
                data: false,
                error: "Wrong return code (" + resp.data.statusCode.toString() + ")"
              }
            }
          )
        }
      }
    ).catch( error => {
        //console.log('error', error);
        this.setState(
          {
            submissionResult:  {
              success: false,
              data: false,
              error: error
            }
          }
        )
      }
    )
  }

  handleSubmitFile() {
    let submissionBody = JSON.parse( this.state.submissionResult.data )
    let formData = new FormData()
    Object.keys(submissionBody.fields).forEach(
      key => {
        formData.append(key, submissionBody.fields[key])
      }
    )
    formData.append( "file", this.state.selectedFile)
    let url = submissionBody.url
    const axios = require("axios")
    axios(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }
    ).then(
      resp => {

        //console.log("file submission ok")
        //console.log(resp.status);

        if( 204 === resp.status ) {
          this.setState(
            {
              submitFileResult: {
                success: true,
                data: resp.data,
                error: false
              }
            }
          )
        } else {
          this.setState(
            {
              submitFileResult:  {
                success: false,
                data: false,
                error: "Wrong return code (" + resp.status.toString() + ")"
              }
            }
          )
        }
      }
    ).catch( error => {
        //console.log('error', error);
        this.setState(
          {
            submitFileResult:  {
              success: false,
              data: false,
              error: error
            }
          }
        )
      }
    )

  }

  validateData( currentDataInput ) {
    let return_value = {
      "type": {
        "value": null,
        "error": this.props.t("fn.error.type.blank"),
      },
      "description": {
        "value": "",
        "error": this.props.t("fn.error.description.blank")
      },
      "url": {
        "value": "",
        "error": null
      },
      "hash": {
        "value": "",
        "error": null
      },
      "inputA": {
        "value": ""
      },
      "inputB": {
        "value": ""
      },
      "software": {
        "value": "",
        "error": null
      },
      "detection": {
        "value": "",
        "error": null
      },
      "step": {
        "value": 1
      }
    }
    if( currentDataInput && currentDataInput.length ) {
      let json_data = JSON.parse( currentDataInput )
      if( json_data.hasOwnProperty( "inputA" ) ) {
        let validated_inputA = this.validateInputA( json_data["inputA"] )
        if( !validated_inputA["error"] ) {
          return_value["inputA"] = {
            "value": validated_inputA["inputA"],
          }
          return_value["step"] = 2
        }
      }
      if( 2 === return_value["step"] ) {
        if( json_data.hasOwnProperty( "inputB" ) ) {
          let validated_inputB = this.validateInputB( json_data["inputB"] )
          if( ! validated_inputB["error"] ) {
            return_value["inputB"] = {
              "value": validated_inputB["inputB"],
            }
            return_value["step"] = 4
          }
        }
      }
      if( 4 === return_value["step"] ) {
        if( json_data.hasOwnProperty( "type" ) ) {
          let types = [ "FILE", "URL", "HASH" ]
          if( types.includes( json_data["type"] ) ) {
            return_value["type"] = {
              "value": json_data["type"]
            }
            return_value["step"] = 5
          }
        }
      }
      if( 5 === return_value["step"] ) {
        if( ( "URL" === json_data["type"] ) && json_data.hasOwnProperty("url") ) {
          let validated_url = this.validateURL( json_data["url"] )
          return_value["url"] = {
            "value": validated_url["url"],
            "error": validated_url["error"]
          }
        } else if( ( "HASH" === json_data["type"] ) && json_data.hasOwnProperty("hash") ) {
          let validated_hash = this.validateHash( json_data["hash"] )
          return_value["hash"] = {
            "value": validated_hash["hash"],
            "error": validated_hash["error"]
          }
        }
      }
      if( json_data.hasOwnProperty( "description" ) ) {
        let validated_description = this.validateDescription( json_data["description"] )
        return_value["description"] = {
          "value": validated_description["description"],
          "error": validated_description["error"]
        }
      }
      if( json_data.hasOwnProperty( "software" ) ) {
        let validated_software = this.validateSoftware( json_data["software"] )
        return_value["software"] = {
          "value": validated_software["software"],
          "error": validated_software["error"]
        }
      }
      if( json_data.hasOwnProperty( "detection" ) ) {
        let validated_detection = this.validateDetection( json_data["detection"] )
        return_value["detection"] = {
          "value": validated_detection["detection"],
          "error": validated_detection["error"]
        }
      }
    }
    return return_value
  }


  // if the submission succeeded, this function will call the setSubmissionResult function which will unmount this component
  handleFinishedSubmission() {
    //console.log( "Handling finished submission" )
    if( "FILE" === this.state.selectedType ) {
      if( this.state.submissionResult.success ) {
        if( false === this.state.submitFileResult ) { // if we have not actually submitted the file yet
          //console.log("File data ok, uploading file")
          this.handleSubmitFile()
        } else {
          if( this.state.submitFileResult.success ) { //File was submitted OK
            //console.log("File upload OK")
            this.setSuccessfulSubmissionResult()
          } else { //File upload failed
            let error = (
              <div className="failedSubmission">
                <Trans i18nKey="fp.failedMessage.file" />
              </div>
            )
            this.setFailedSubmissionResultMessage(error)
          }
        }
      } else {
        // if we didn't get a proper reply from the server when uploading data
        let error = (
          <div className="failedSubmission">
            <Trans i18nKey="fp.failedMessage.file" />
          </div>
        )
        //console.log( "set failed!" )
        this.setFailedSubmissionResultMessage(error)
      }
    } else if( "URL" === this.state.selectedType ) {
      if( this.state.submissionResult.success ) {
        //console.log("URL OK")
        this.setSuccessfulSubmissionResult()
      } else {
        let error = (
          <div className="failedSubmission">
            <Trans i18nKey="fp.failedMessage.url" />
          </div>
        )
        this.setFailedSubmissionResultMessage(error)
      }
    } else if( "HASH" === this.state.selectedType ) {
      if( this.state.submissionResult.success ) {
        //console.log("HASH OK")
        this.setSuccessfulSubmissionResult()
      } else {
        let error = (
          <div className="failedSubmission">
            <Trans i18nKey="fp.failedMessage.hash" />
          </div>
        )
        this.setFailedSubmissionResultMessage(error)
      }
    }
  }

  setFailedSubmissionResultMessage( message ) {
    //console.log( this.state.submissionResult.error )
    this.setState( {
      "userClickedSubmit": false,
      "messageToUser": message,
      "recaptcha": null,
      "submissionResult":  false,
      "submitFileResult":  false,
      "progress": false,
      "selectedFile": null,
    } )
  }

  setSuccessfulSubmissionResult() {
    let check_link = "/?type=CHECK&submission_id=" + this.state.submissionID
    let success = (
      <div className="successfulSubmission">
        <Trans i18nKey="submission.successMessage">
          {{ SubmissionID: this.state.submissionID }}
        </Trans>
        //<a href={check_link}>{ this.props.t('submission.checkStatus') }</a>
      </div>
    )
    this.props.setSubmissionResult(success)
    this.setState( { "userClickedSubmit": false } ) // this will not really run
  }

  getSubmissionProgress() {
    const spinner = (
      <Container className="Portal-SubmissionSpinnerContainer">
        <Row>
          <Spinner
             animation="grow"
             size='lg'
             role="status"
             className="Portal-SubmissionSpinner"
          />
        </Row>
        <Row className="Portal-SubmittingText">
          {this.props.t('submission.submissionPending')}
          <br />
          ...
        </Row>
      </Container>
    )
    return (
      <div>
          {spinner}
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(this.state)

    /*
      This is triggered when the user clicks on the submit button
      It is added like this to be able to show a progress bar or similar as soon as the user clicks on the button
    */
    if( this.state.userClickedSubmit !== prevState.userClickedSubmit ) {
      if( true === this.state.userClickedSubmit ) {
        this.handleSubmit()
      }
    }

    //if the change is that we submitted the form to the API and got a result
    if( this.state.submissionResult !== prevState.submissionResult ) {
      if( this.state.submissionResult ) {
        this.handleFinishedSubmission()
      } else {
        //we changed to False, so we are done!
      }
    }

    //if the change is that we submitted the file to S3 and got a result
    if( this.state.submitFileResult !== prevState.submitFileResult ) {
      if( this.state.submitFileResult ) {
        this.handleFinishedSubmission()
      } else {
        //we changed to False, so we are done!
      }
    }
  }

  /****** FORM HANDLERS ******/
  inputAHandleChange(id) {
    this.setState({ step: 2, selectedA: id } )
    //console.log( id )
  }

  validateInputA( id ) {
    let return_value = {
      "value": "",
      "error": "Input A not valid. This will not happen unless code has been tampered with."
    }
    let options = this.getOptionsArrayA()
    for( let i in options ) {
      if( id === options[i]["id"] ) {
        return_value = {
          "inputA": options[i]["id"],
          "error": null
        }
      }
    }
    return return_value
  }

  inputBHandleChange(id) {
    this.setState({ step: 4, selectedB: id } )
    //console.log( id )
  }

  validateInputB( id ) {
    let return_value = {
      "value": "",
      "error": "Input B not valid. This will not happen unless code has been tampered with."
    }
    let options = this.getOptionsArrayB()
    for( let i in options ) {
      if( id === options[i]["id"] ) {
        return_value = {
          "inputB": options[i]["id"],
          "error": null
        }
      }
    }
    return return_value
  }

  inputDHandleChange(id) {
    //console.log( id )
    let tmp_type = id
    let tmp_error = {
      ...this.state.error,
      file: null,
      url: null,
      hash: null,
    }
    if( "URL" === tmp_type ) {
      tmp_error = {
        ...tmp_error,
        url: this.props.t("fp.error.url.blank")
      }
    } else if( "FILE" === tmp_type ) {
      tmp_error = {
        ...tmp_error,
        file: this.props.t("fp.error.file.blank")
      }
    } else if( "HASH" === tmp_type ) {
      tmp_error = {
        ...tmp_error,
        hash: this.props.t("fp.error.hash.blank")
      }
    }
    this.setState(
      {
        step: 5,
        selectedD: tmp_type,
        selectedType: tmp_type,
        error: tmp_error,
        selectedFile: null,
        hash: "",
        url: "",
      }
    )
  }

  handleFileSelectChange(event) {
    if( event.target.files && event.target.files.length > 0 ) {
      this.setState(
        {
          selectedFile: event.target.files[0],
          error: {
            ...this.state.error,
            file: null
          }
        }
      )
    } else {
      this.setState(
        {
          selectedFile: null,
          error: {
            ...this.state.error,
            file: this.props.t("fp.error.file.blank")
          }
        }
      )
    }
  }

  handleUrlChange(event) {
    let validated_url = this.validateURL( event.target.value )
    this.setState(
      {
        url: validated_url["url"],
        error: {
          ...this.state.error,
          url: validated_url["error"]
        }
      }
    )
  }

  ltrim(str) {
    if(!str) return str;
      return str.replace(/^\s+/g, '');
  }

  validateURL( url ) {
    let tmp_url = this.ltrim( url )
    let return_value = {
      "url": tmp_url,
      "error": null
    }
    if( ! tmp_url.length ) {
      return_value["error"] = this.props.t("fp.error.url.blank")
    } else if( ! /^(ftp|http|https):\/\/[^ "]+$/.test( tmp_url ) ) {
      return_value["error"] = this.props.t("fp.error.url.invalid")
    }
    return return_value
  }

  handleHashChange(event) {
    let validated_hash = this.validateHash( event.target.value )
    this.setState(
      {
        hash: validated_hash["hash"],
        error: {
          ...this.state.error,
          hash: validated_hash["error"]
        }
      }
    )
  }

  validateHash( hash ) {
    let tmp_hash = hash.trim()
    let return_value = {
      "hash": tmp_hash,
      "error": null
    }
    if( ! tmp_hash.length ) {
      return_value["error"] = this.props.t("fp.error.hash.blank")
    } else if( ! /^([A-Fa-f0-9]{32})|([A-Fa-f0-9]{64})$/.test( tmp_hash ) ) {
      return_value["error"] = this.props.t("fp.error.hash.invalid")
    }
    return return_value
  }

  handleSoftwareChange(event) {
    let validated_software = this.validateSoftware( event.target.value )
    this.setState(
      {
        software: validated_software["software"],
        error: {
          ...this.state.error,
          software: validated_software["error"]
        }
      }
    )
  }

  validateSoftware( software ) {
    let tmp_software = this.ltrim( software )
    let return_value = {
      "software": tmp_software,
      "error": null
    }
    if( 255 < tmp_software.length ) {
      return_value["error"] = this.props.t("fp.error.software.tooLong")
    }
    return return_value
  }

  handleDetectionChange(event) {
    let validated_detection = this.validateDetection( event.target.value )
    this.setState(
      {
        detection: validated_detection["detection"],
        error: {
          ...this.state.error,
          detection: validated_detection["error"]
        }
      }
    )
  }

  validateDetection( detection ) {
    let tmp_detection = this.ltrim( detection )
    let return_value = {
      "detection": tmp_detection,
      "error": null
    }
    if( 255 < tmp_detection.length ) {
      return_value["error"] = this.props.t("fp.error.detection.tooLong")
    }
    return return_value
  }

  handleDescriptionChange(event) {
    let validated_description = this.validateDescription( event.target.value )
    this.setState(
      {
        description: validated_description["description"],
        error: {
          ...this.state.error,
          description: validated_description["error"]
        }
      }
    )
  }

  validateDescription( description ) {
    let tmp_description = this.ltrim( description )
    let return_value = {
      "description": tmp_description,
      "error": null
    }
    if( 65535 < tmp_description.length ) {
      return_value["error"] = this.props.t("fp.error.description.tooLong")
    } else if( ! tmp_description.length) {
      return_value["error"] = this.props.t("fp.error.description.blank")
    }
    return return_value
  }

  handleRecaptchaChange(value) {
    this.setState( { recaptcha: value } )
  }

  goBack(event) {
    if( 5 === this.state.step ) {
      this.setState( { step: 4, selectedD: "" } )
    } else if( 4 === this.state.step ) {
      this.setState( { step: 2, selectedB: "" } )
    } else if( 2 === this.state.step ) {
      this.setState( { step: 1, selectedA: "" } )
    }
  }

  handleUserClickSubmit(event) {
    //console.log("User clicked submit!")
    if( !this.formHasErrors() ) {
      this.setState( { "userClickedSubmit": true } )
    }
  }

  /****** CONTENT ******/
  getOptionsArrayA() {
    return [
      {
        "id": "A1",
        "text": this.props.t('fp.input.A.option.1')
      },
      {
        "id": "A2",
        "text": this.props.t('fp.input.A.option.2')
      },
      {
        "id": "A3",
        "text": this.props.t('fp.input.A.option.3')
      },
      {
        "id": "A4",
        "text": this.props.t('fp.input.A.option.4')
      },
      {
        "id": "A5",
        "text": this.props.t('fp.input.A.option.5')
      },
      {
        "id": "A6",
        "text": this.props.t('fp.input.A.option.6')
      },
      {
        "id": "A7",
        "text": this.props.t('fp.input.A.option.7')
      },
      {
        "id": "A8",
        "text": this.props.t('fp.input.A.option.8')
      }
    ]
  }

  inputA(){
    let options_array = this.getOptionsArrayA()
    return (
      <NLLRadio
        header={this.props.t('fp.input.A.header')}
        options={options_array}
        name="myOptionsA"
        handleChange={this.inputAHandleChange}
        selected={this.state.selectedA}
        key="NLLRadioA"
      />
    )
  }

  getOptionsArrayB() {
    return [
      {
        "id": "B4",
        "text": this.props.t('fp.input.B.option.1')
      },
      {
        "id": "B5",
        "text": this.props.t('fp.input.B.option.2')
      },
      {
        "id": "B6",
        "text": this.props.t('fp.input.B.option.3')
      },
      {
        "id": "B7",
        "text": this.props.t('fp.input.B.option.4')
      },
      {
        "id": "B12",
        "text": this.props.t('fp.input.B.option.5')
      },
      {
        "id": "B13",
        "text": this.props.t('fp.input.B.option.6')
      },
      {
        "id": "B16",
        "text": this.props.t('fp.input.B.option.7')
      }
    ]
  }

  inputB(){
    let options_array = this.getOptionsArrayB()
    return (
      <NLLRadio
        header={this.props.t('fp.input.B.header')}
        options={options_array}
        name="myOptionsB"
        handleChange={this.inputBHandleChange}
        selected={this.state.selectedB}
        key="NLLRadioB"
      />
    );
  }

  inputD(){
    let header = (
      <div  className="inputHeader">
        <Trans i18nKey="fp.input.type.header" />
      </div>
    )
    let options_array = [
      { "id": "FILE", "text": this.props.t('fp.input.type.file')},
      { "id": "URL", "text": this.props.t('fp.input.type.url')},
      { "id": "HASH", "text": this.props.t('fp.input.type.hash')},
    ]
    return (
      <NLLRadio
        header={header}
        options={options_array}
        name="myOptionsD"
        handleChange={this.inputDHandleChange}
        selected={this.state.selectedD}
        key="NLLRadioD"
      />
    )
  }

  inputE() {
    let fields = (
      <Container className="Portal-FormContainer">
        <Row className="Portal-RowInputFileUrlHash">
          <Col md={12}>
            {this.inputFileUrlHash()}
          </Col>
        </Row>
        <Row className="Portal-RowInputType">
          <Col md={12}>
            {this.inputSoftware()}
          </Col>
        </Row>
        <Row className="Portal-RowInputType">
          <Col md={12}>
            {this.inputDetection()}
          </Col>
        </Row>
        <Row className="Portal-RowInputDescription">
          <Col md={12}>
            {this.inputDescription()}
          </Col>
        </Row>
        <Row className="Portal-RowLegal">
          <Col md={12}>
            <NLLLegal />
          </Col>
        </Row>
        <Row className="Portal-RowReCaptcha">
          <Col md={12}>
            <ReCAPTCHA
              sitekey="6LeQTcUUAAAAAJJ28uHBNLl6ZXyZ74F4qt4N5v2e"
              onChange={this.handleRecaptchaChange}
              key="captcha"
            />
          </Col>
        </Row>
        <Row className="Portal-RowSubmit">
          <Col md={12}>
            <NLLSubmit
              isDisabled={false}
              key="submit"
              name="submit"
              missingText="Missing values"
              submitText={this.props.t('submission.submitButton')}
              handleClick={this.handleUserClickSubmit}
              className="Portal-SubmitButton"
            />
          </Col>
        </Row>
      </Container>
    )
    return fields
  }

  inputFileUrlHash() {
    let return_result = ""
    if( "FILE" === this.state.selectedD ) {
      return_result = (
        <NLLFile
          name="fileupload"
          header=""
          handleChange={this.handleFileSelectChange}
          key="fileupload"
          error={this.state.error.file}
        />
      )
    } else if( "URL" === this.state.selectedD ) {
      return_result = (
        <NLLInput
          placeholder={this.props.t('fp.input.fileurlhash.url.placeholder')}
          name="url"
          key="url"
          header={this.props.t('fp.input.fileurlhash.url.header')}
          handleChange={this.handleUrlChange}
          storedValue={this.state.url}
          error={this.state.error.url}
        />
      )
    } else if( "HASH" === this.state.selectedD ) {
      return_result = (
        <NLLInput
          placeholder={this.props.t('fp.input.fileurlhash.hash.placeholder')}
          name="hash"
          key="hash"
          header={this.props.t('fp.input.fileurlhash.hash.header')}
          handleChange={this.handleHashChange}
          storedValue={this.state.hash}
          error={this.state.error.hash}
        />
      )
    }
    return return_result
  }

  inputSoftware(){
    return (
      <NLLInput
        placeholder={this.props.t('fp.input.software.placeholder')}
        name="software"
        key="software"
        header={this.props.t('fp.input.software.header')}
        handleChange={this.handleSoftwareChange}
        storedValue={this.state.software}
        error={this.state.error.software}
      />
    )
  }

  inputDetection(){
    return (
      <NLLInput
        placeholder={this.props.t('fp.input.detection.placeholder')}
        name="detection"
        key="detection"
        header={this.props.t('fp.input.detection.header')}
        handleChange={this.handleDetectionChange}
        storedValue={this.state.detection}
        error={this.state.error.detection}
      />
    )
  }

  inputDescription(){
    let header = (
      <div  className="inputHeader">
        <Trans i18nKey="fp.input.description.header" />
      </div>
    )
    return (
      <NLLText
        name="description"
        key="description"
        header={header}
        handleChange={this.handleDescriptionChange}
        storedValue={this.state.description}
        error={this.state.error.description}
      />
    )
  }

  inputGoBackButton() {
    return (
      <div key="back">
        <NLLSubmit
          isDisabled={false}
          key="back"
          name="back"
          missingText="Missing values"
          submitText={this.props.t('submission.goBackButton')}
          handleClick={this.goBack}
          className="Portal-GoBackButton"
        />
      </div>
    )
  }

  render() {
    let return_result = ""
    if( true === this.state.userClickedSubmit ) {
      return_result = this.getSubmissionProgress()
    } else {
      let A = this.inputA()
      let B = ""
      let D = ""
      let E = ""
      let goBackButton = this.inputGoBackButton()
      if( this.state.step > 1 ) {
        B = this.inputB()
      }
      if( this.state.step > 3 ) {
        D = this.inputD()
      }
      if( this.state.step > 4 ) {
        E = this.inputE()
      }
      return_result = (
        <Container className="Portal-FormContainer">
          <Row className="Portal-MessageToUser">
            <Col md={12}>
              {this.state.messageToUser ? this.state.messageToUser : false}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <NLLExplanation
                translationKey="fp.input.explanation"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {A}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {B}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {D}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {E}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {goBackButton}
            </Col>
          </Row>
        </Container>
      )
    }
    return ( return_result );
  }
}

NLLFP.propTypes = {
  setSubmissionResult: PropTypes.func,
  data: PropTypes.string,
}

export default withTranslation()(NLLFP)
