import React from 'react';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Form from 'react-bootstrap/Form'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NLLHelp from "./NLLHelp"

import Card from 'react-bootstrap/Card'

class NLLFile extends React.Component {
  render() {
    return (
      <Card>
        <Card.Header>
          <Row className='Portal-FormRowText'>
            <Col md={11}>{this.props.header}</Col>
            <Col md={1}>
              <NLLHelp helpContent={this.props.helpContent} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form.Control
            type="file"
            name={this.props.name}
            onChange={this.props.handleChange}
            key={this.props.name}
          />
          {
            this.props.error ? (
              <span className="Portal-FormError">
                {this.props.error}
              </span>
            ) : ""
          }
          {
            this.props.footer ? (
              <Card.Footer>
                {this.props.footer}
              </Card.Footer>
            ) : null
          }
        </Card.Body>
      </Card>
    )
  }
}

NLLFile.propTypes = {
  header: PropTypes.string,
  footer: PropTypes.object,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  helpContent: PropTypes.object,
  error: PropTypes.string,
}

export default NLLFile
